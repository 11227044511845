import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Step, StepButton, Stepper, TextField, Typography } from "@mui/material";
import useStores from "../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, PAGE_SIZE } from "../constants/options";
import VmModal from "../components/shared-modal";
import { t } from "i18next";
import ContentLayout from "../components/shared-content-layout";
import { THEME_BUTTON } from "../constants/style";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PersonalInfoTab from "../components/student-registration-component/personal-info-tab";
import AdmissionQualificationTab from "../components/student-registration-component/admission-qualification-tab";
import CreditTransferTab from "../components/student-registration-component/credit-transfer-tab";
import SummaryTab from "../components/student-registration-component/summary-tab";
import { DefaultAdmissionQuaDataObj, DefaultCreditTransferDataObj, DefaultPersonalInfoDataObj, EnrollForm, SectionLocation } from "../constants/registration-options";

const RegistrationPage = observer(() => {
  const { rootStore } = useStores();
  const stepLabels = [t('PERSONAL_INFORMATION'), t('ADMISSION_QUALIFICATIONS'), t('CREDIT_TRANSFER'), 'Summary'];
  const [personalInfoData, setPersonalInfoData] = useState<any>({});
  const [admissionQuaData, setAdmissionQuaData] = useState<any>({});
  const [creditTransferData, setCreditTransferData] = useState<any>({});
  const [summaryData, setSummaryData] = useState<EnrollForm>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [step, setStep] = useState<number>(0);
  const [onHoldStep, setOnHoldStep] = useState<number>(-1);
  const [pageLoc, setPageLoc] = useState<number>(0);
  const [completed, setCompleted] = useState<any>([false, false, false, false]);

  useEffect(() => {
    setPersonalInfoData(DefaultPersonalInfoDataObj);
    setAdmissionQuaData(DefaultAdmissionQuaDataObj);
    setCreditTransferData(DefaultCreditTransferDataObj);
  }, []);

  const onCompareDataSource = (pageSource: any, storedSource: any) => {
    return JSON.stringify(pageSource) === JSON.stringify(storedSource);
  }

  const handleShowModal = (activeStep: number) => {
    if (step == 3) setStep(activeStep);
    else {
      setShowModal(true);
      setOnHoldStep(activeStep);
    }
  }

  const onCloseModal = (complete: boolean) => {
    if (complete) setStep(onHoldStep);
    setOnHoldStep(-1);
    setShowModal(false);
  }

  const goPreviousStep = (paramInfo: any) => {
    console.log("paramInfo", paramInfo);
    switch (step) {
      case 0: setPersonalInfoData(paramInfo);
        break;
      case 1: setAdmissionQuaData(paramInfo);
        break;
      case 2: setCreditTransferData(paramInfo);
        break;
    }
    completed[step] = true;
    let tempComplete = completed;
    setCompleted(tempComplete);
    setStep(step - 1);
    window.scrollTo(0, 0);
  }

  const onCompleteToNextStep = (paramInfo: any) => {
    console.log("paramInfo", paramInfo);
    let tempPersonalData: any = personalInfoData;
    let tempAdmissionData: any = admissionQuaData;
    let tempCreditData: any = creditTransferData;
    switch (step) {
      case 0: setPersonalInfoData(paramInfo);
        tempPersonalData = paramInfo;
        break;
      case 1: setAdmissionQuaData(paramInfo);
        tempAdmissionData = paramInfo;
        break;
      case 2: setCreditTransferData(paramInfo);
        tempCreditData = paramInfo;
        break;
    }
    console.log((step + 1), completed.filter(c => c == false), (step + 1) == 3 && completed.filter(c => c == false).length > 1);

    if ((step + 1) == 3 && completed.filter(c => c == false).length > 2) {
      rootStore.notify("Please complete all steps before approaching to Summary", "warning");
    } else {
      completed[step] = true;
      let tempComplete = completed;
      setCompleted(tempComplete);
      setSummaryData({
        personalInformation: tempPersonalData,
        admissionQualifications: tempAdmissionData,
        creditTransfer: tempCreditData,
      });
      setStep(step + 1);
      window.scrollTo(0, 0);
    }
  }

  const onNavigate = (location: SectionLocation) => {
    switch (location) {
      case SectionLocation.ADMISSION:
        setStep(1);
        window.scrollTo(0, 0);
        break;
      case SectionLocation.CREDIT:
        setStep(2);
        window.scrollTo(0, 0);
        break;
      default:
        setStep(0);
        setPageLoc(location);
        break;
    }
  }

  return (
    <ContentLayout
      pageName="Enrol Form"
      pageHeading=""
      breadCrumb={[]}
      showHeadingButton={false}
    // buttonLabel={t('ADD_A') + t('FORM')}
    >
      <Typography textAlign={"center"} mb={4} variant="h5">Complete your enrol form to activate your student account</Typography>
      <div className="px-10">
        <Stepper activeStep={step} nonLinear alternativeLabel>
          {stepLabels.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton disabled={index == 3 && completed.filter(c => c == false).length > 1}
                sx={{ color: THEME_BUTTON }} onClick={() => handleShowModal(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ py: 2 }}>
          {step == 0 ? <PersonalInfoTab pageLocation={pageLoc} prefillData={personalInfoData} onCompleteToNextStep={onCompleteToNextStep} />
            : step == 1 ? <AdmissionQualificationTab prefillData={admissionQuaData} goPreviousStep={goPreviousStep} onCompleteToNextStep={onCompleteToNextStep} />
              : step == 2 ? <CreditTransferTab prefillData={creditTransferData} goPreviousStep={goPreviousStep} onCompleteToNextStep={onCompleteToNextStep} />
                : step == 3 && <SummaryTab onNavigate={onNavigate} summaryData={summaryData} />}
        </Box>
      </div>

      <VmModal
        openModal={showModal}
        onClose={() => setShowModal(false)}
        title={"Warning"}
        icon={<Box className="text-highlight" marginRight={1}><ErrorOutlineIcon color="inherit" /></Box>}
        showButton={false}
        width={400}
      >
        <Typography variant="body1" textAlign="center" marginBottom={2}>Any unsaved inputs in this step will lost!</Typography>
        <div className="text-right p-4">
          <Button
            variant="outlined"
            onClick={() => onCloseModal(true)}
            color="success"
          >
            YES, PROCEED
          </Button>
          <Button
            variant="outlined"
            onClick={() => onCloseModal(false)}
            sx={{ marginLeft: 2 }}
            color="error"
          >
            {t('CANCEL')}
          </Button>
        </div>
      </VmModal>
    </ContentLayout>
  )
});

export default RegistrationPage;
